@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-50;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-select-container .react-select__control {
  @apply rounded-lg border border-gray-200 shadow-sm transition-colors;
}

.react-select-container .react-select__control:hover {
  @apply border-gray-300;
}

.react-select-container .react-select__control--is-focused {
  @apply border-blue-500 ring-1 ring-blue-500;
  box-shadow: none;
}

.react-select-container .react-select__menu {
  @apply mt-1 rounded-lg border border-gray-200 bg-white shadow-lg;
}

.react-select-container .react-select__option {
  @apply px-3 py-2 text-sm;
}

.react-select-container .react-select__option--is-focused {
  @apply bg-blue-50;
}

.react-select-container .react-select__option--is-selected {
  @apply bg-blue-500 text-white;
}

.react-select-container .react-select__value-container {
  @apply py-1;
}

.react-select-container .react-select__input-container {
  @apply text-sm;
}

.react-select-container .react-select__single-value {
  @apply text-sm text-gray-900;
}

.dark .fc {
  background-color: #18181b; /* zinc-900 */
  color: #ffffff;
}

.dark .fc-toolbar {
  background-color: #18181b;
  color: #ffffff;
}

.dark .fc-button {
  background-color: #27272a;
  border: none;
  color: #ffffff;
}

.dark .fc-button.fc-button-active {
  background-color: #52525b;
  color: #ffffff;
}

.dark .fc-event {
  background-color: #0ea5e9;
  color: #ffffff;
  border: none;
}

.dark .fc-daygrid-day {
  background-color: #18181b;
  color: #a1a1aa;
}

.dark .fc-daygrid-day-number {
  color: #a1a1aa;
}

.dark .fc-day-today {
  background-color: #27272a;
  color: #ffffff;
}

/* Ag Tables */
.ag-theme-balham {
  /* disable all borders */
  --ag-borders: none;
}

.ag-root-wrapper {
  border: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-corner {
  background: #f4f4f5;
}

::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

.dark ::-webkit-scrollbar-corner {
  background: #0C2238;
}

.dark ::-webkit-scrollbar-track {
  background: #17191A;
}

.dark ::-webkit-scrollbar-thumb {
  background: #2C323A; 
}

.dark ::-webkit-scrollbar-thumb:hover {
  background: #20252C; 
}
