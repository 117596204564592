
.logo-rectangle-container {
  perspective: 500px;
  transform-style: preserve-3d;
}

.rotateY {
  animation: rotateY 5s ease-in-out infinite alternate-reverse;
}

.rotateYfixed {
  transform: rotateY(45deg);
}

@keyframes rotateY {
  0% {
    transform: none;
  }
  100% {
    transform: rotateY(360deg);
  }
}
